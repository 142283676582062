import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import * as QueryString from 'query-string'
import claimProjectReportsListsService from '@services/lists/claim_groups/claim_project_reports_lists.service'
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice'
import { Loading } from '@rd-web-markets/shared/dist/util'
import Pagination from '@rd-web-markets/shared/dist/util/Pagination';
import ClaimProjectReportsList from '@components/admin/claimProjectReports/ClaimProjectReportsList'
import { buildQueryString } from '@rd-web-markets/shared/dist/services/service'

const initialParams = {
  page: 1,
  order_by: 'report_order',
  group_projects: false,
  query: ''
}

export default function ProjectsList({ claimGroup, additionalQueryParams = {}, setClaimGroup, accountType, handleToaster, groupProjects = false }) {
  const [loading, setLoading] = useState(false)
  const [reports, setReports, totalPages, setTotalPages, params, setParams, fetchAllProjetcs] = claimProjectReportsListsService.useFetchAll({
    accountType,
    claimGroupId: claimGroup.id,
    initialQueryParams: buildQueryString({
      ...initialParams,
      ...additionalQueryParams
    }),
    setLoading,
    groupProjects
  })

  const handlePage = (pageNumber) => {
    window.scrollTo(0, 0)
    setParams({ ...params, page: pageNumber })
  }

  if(!reports) return <Loading />

  return <>
    <ClaimProjectReportsList
      handleToaster={handleToaster}
      claimGroup={claimGroup}
      getData={fetchAllProjetcs}
      setClaimGroup={setClaimGroup} 
      reports={reports} 
      params={params} 
      setParams={setParams}
      groupProjects={groupProjects}
    />
    <Pagination currentPage={Number(params.page)} totalPages={totalPages} handlePageChange={handlePage} />
  </>
}
