export default function wordCount(text) {
  // Remove leading and trailing white spaces
  text = text.trim();

  // Split the text into an array of words
  const words = text.split(/\s+/).filter(w => !!w.length);
  
  // Return the number of words
  return words.length;
}
