import React, { useRef, useState } from 'react'
import { Row, Col} from 'react-bootstrap';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import CostTemplateErrorsModal from '@components/modals/CostTemplateErrorsModal';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';

const TechnicalNarrativeTemplateUploadSection = ({
  loading,
  claimGroup,
  handleToaster,
  handleUploadTechnicalNarrative,
}) => {
  const hiddenFileInput = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const technicalNarrativeUploadStatus = claimGroup.technical_narrative_upload_statuses.slice(-1)[0];

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const submitUpload = async (e) => {
    if(e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      await handleUploadTechnicalNarrative(e);
      hiddenFileInput.current.value = null;
    } else {
      handleToaster('Please upload docx file', 'warning')
    }
  }

  const generateTechnicalNarrative = () => {
    window.open(`/api/claim_groups/${claimGroup.id}/technical_narrative_export`);
  };

  const rows = () => {
    return (technicalNarrativeUploadStatus?.import_errors || []).map((error) => ({
      columns: [error]
    }));
  }

  const modalBody = () => {
    return <Table headers={[{ text: 'Error' }]} rows={rows()} showControls={false} />;
  }

  const renderStatus = () => {
    if(technicalNarrativeUploadStatus){
      switch(technicalNarrativeUploadStatus.import_status) {
        case 'imported_successfully':
          return 'Template was imported successfully';
        case 'imported_unsuccessfully':
          return (
            <span>
              There were errors importing the file.
              <Button style={{padding: 0, margin: 0}} variant="link" onClick={() => setModalShow(true)}>
                You can check them here
              </Button>
            </span>
          )
        default:
          return 'Pending'
      }
    } else { 
      return 'Template was never imported';
    }
  }

  const importTableHeaders = [
    { text: 'Date',
      text: 'Uploaded By',
      text: ''
    }
  ];

  const importTableRows = () => {
    return claimGroup?.technical_narrative_upload_statuses?.filter(im => im.import_status !== 'never_imported').reverse().map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.imported_by,
        <a 
          href={im.download_links.url} target="_blank" rel="noreferrer"
        >
          {im.download_links.filename}
        </a>
      ]
    }));
  }


  return (
    <div>
      <CostTemplateErrorsModal
        title='Import Errors'
        body={modalBody()}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />

      <Row className="scrollable">
          <Col md={6} className="text-center">
            <Button
              isImmutableUnderReview={true}
              variant="info"
              iconPosition="left"
              size="md"
              icon="upload_file"
              loading={loading}
              className="mb-2"
              id="upload-technical-narrative-template-button"
              onClick={handleClick}
              >
                Upload Technical Narrative Template
            </Button>
              <div>
                {renderStatus()}
              </div>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={hiddenFileInput}
              data-testid='narrativeTemplateUpload'
              onChange={(e) => submitUpload(e)}
            ></input>
          </Col>
          <Col md={6} className="text-center">
            <Button
              variant="info"
              iconPosition="left"
              size="md"
              icon='download'
              loading={loading}
              id="download-technical-narrative-template-button"
              className="mb-4"
              onClick={generateTechnicalNarrative}
              >
                Download Technical Narrative Template
            </Button>
          </Col>
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} className='text-center'>
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </div>
  )
}

export default TechnicalNarrativeTemplateUploadSection