import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../../hooks/useErrorHandling';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import isEqual from 'lodash.isequal';
const api = buildService('/companies/{id}/claim_groups', {
  only: ['all']
});
const getClaimGroupService = {
  useGetAllClaimGroups: function () {
    let companyId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
    const [allClaimGroups, setAllClaimGroups] = useState([]);
    const getAllClaimGroups = useErrorHandling(useCallback(async () => {
      const claimGroups = await api.all(companyId);
      setAllClaimGroups([...claimGroups]);
    }, [companyId]));
    useEffect(() => {
      getAllClaimGroups();
    }, [getAllClaimGroups]);
    return [allClaimGroups];
  }
};
getClaimGroupService.useCrud = companyId => {
  const [allClaimGroups] = getClaimGroupService.useGetAllClaimGroups(companyId);
  return {
    allClaimGroups
  };
};
export default getClaimGroupService;