import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from '../store/features/alertSlice';
export function useFileUpload(uploadCallback) {
  let formName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'file';
  let uploadAllowed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  useEffect(() => {
    const uploadFile = async () => {
      setIsLoading(true);
      let fd = new FormData();
      fd.append(formName, file);
      try {
        // could only contain attachment id, url, filename
        await uploadCallback(fd);
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        setIsLoading(false);
        setFile(null);
      }
    };
    if (file && !file.id && uploadAllowed) {
      uploadFile();
    }
  }, [dispatch, file, uploadCallback, uploadAllowed, formName]);
  return [file, isLoading, setFile];
}